<template>
  <div>
    <b-overlay :show="showOverlay" rounded="sm">
      <b-card style="max-width: 100%; width: 100%" class="mb-2">
        <b-tabs>
          <b-tab :title="$t('COMMON.MAIN')" active>
            <b-card-body>
              <AddScatDeviceForm 
                ref="addScatDeviceRef" 
                :powerlines="powerlines" 
                @submit="onSaveClicked" 
                :sensorData="dto.sensor || {}"
              />
            </b-card-body>
          </b-tab>
        </b-tabs>

        <div style="float: right">
          <b-button
            v-if="!readOnly"
            variant="success"
            v-on:click="submitForm"
          >
            {{ $t("COMMON.SAVE") }}
          </b-button>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { API_REQUEST } from "@/core/services/store/api.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { SET_ACTIONS } from "@/core/services/store/actions.module";
import { POWERLINE_LIST_REQUEST } from "@/core/services/store/powerline.module";
import { mapState } from "vuex";
import ApiService from "@/core/services/api.service";
import AddScatDeviceForm from "../content/new_dashboard/components/AddScatDeviceForm.vue";

export default {
  name: "scat-device",
  components: { AddScatDeviceForm },
  data() {
    return {
      showOverlay: true,
      dto: {
        id: null,
        typeId: '297e9ce2-9134-4004-b67f-21c1683aaede',
        teamId: ApiService.teamData.value,
        sensor: null,
        active: true,
      },
      save: {
        resource: "/api/device/save",
        requestType: "POST",
        requestParams: {},
      },
      createNewStr: this.$t("DEVICE.CREATE_NEW_DEVICE"),
      editStr: this.$t("DEVICE.EDIT_DEVICE"),
    };
  },

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      powerlines: state => state.powerline.list,
      isLoadingPowerLines: state => state.powerline.isLoading,
    }),
    readOnly() {
      return (
        !this.user.grants.includes("SYSTEM:ADMIN") &&
        this.dto.id != null &&
        this.dto.teamId != this.teamId
      );
    }
  },

  async mounted() {
    let title = !this.$route.params.id ? this.createNewStr : this.editStr;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("MENU.SCAT_DEVICES"), route: "/skat" },
      { title }
    ]);
    this.$store.dispatch(SET_ACTIONS, null);
    await this.loadPowerLines();
  },

  async created() {
    if (this.$route.params.id) {
      await this.load();
    }
    this.showOverlay = false;
  },

  methods: {
    async loadPowerLines() {
      try {
        await this.$store.dispatch(POWERLINE_LIST_REQUEST, {
          teamId: this.dto.teamId,
          size: 1000, // Получаем все ЛЭПы
          page: 0,
          sort: 'name,asc'
        });
      } catch (error) {
        this.onError(error);
      }
    },

    async load() {
      try {
        const response = await this.$store.dispatch(API_REQUEST, {
          resource: "/api/device/" + this.$route.params.id + "/details",
          requestType: "GET",
          requestParams: { enrich: true },
        });
        this.dto = response;
      } catch (error) {
        this.onError(error);
      }
    },

    submitForm() {
      this.$refs.addScatDeviceRef.submitForm();
    },

    async onSaveClicked(sensorData) {
      try {
        this.showOverlay = true;
        const request = this.save;
        request.dto = {
          ...this.dto,
          sensor: sensorData
        };

        const response = await this.$store.dispatch(API_REQUEST, request);
        
        this.$bvToast.toast(this.$t("COMMON.SAVED_SUCCESSFULLY"), {
          title: this.$t("COMMON.SUCCESS"),
          variant: "success",
          autoHideDelay: 5000,
        });

        this.$router.push("/skat");
      } catch (error) {
        this.onError(error);
      } finally {
        this.showOverlay = false;
      }
    },

    onError(response) {
      if (response && response.config) response = response.data;
      this.$bvToast.toast(
        response && response.message
          ? response.message
          : this.$t("COMMON.UNKNOWN_ERROR"),
        {
          title: this.$t("COMMON.ERROR"),
          variant: "danger",
          autoHideDelay: 5000,
        }
      );
    },
  }
};
</script>

<style scoped>
.form-label {
  font-weight: bold;
}

.form-select,
.form-control {
  font-size: 1rem;
}

.btn-primary,
.btn-secondary {
  font-size: 1rem;
}

.invalid-feedback {
  font-size: 0.875rem;
}
</style> 